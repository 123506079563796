import { computed, InjectionKey, onMounted, ref } from "vue";

export const useTimeData = () => {
  const currentDate = ref(new Date());
  const hours = computed(() => {
    return currentDate.value.getHours();
  });
  const minutes = computed(() => {
    return currentDate.value.getMinutes();
  });
  const darkMode = computed(() => hours.value >= 19);

  onMounted(() => {
    setInterval(() => {
      currentDate.value = new Date();
    }, 500);
  });

  return {
    hours,
    minutes,
    darkMode,
  };
};
export type TimeData = ReturnType<typeof useTimeData>;
export const TimeDataKey: InjectionKey<TimeData> = Symbol("TimeDataKey");
