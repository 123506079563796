import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36465a36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "player" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.embedURL)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 0,
          class: "frame",
          src: $setup.embedURL,
          frameborder: "0",
          allow: "autoplay; fullscreen; picture-in-picture;",
          allowfullscreen: "",
          onLoad: $setup.frameLoaded
        }, null, 40, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["cover", { hide: $setup.loaded }])
    }, "loading...", 2)
  ]))
}